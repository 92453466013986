@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', 'Arial';
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
}

#root {
  width: 100%;
  overflow: hidden;
}

.relative {
  position: relative;
  background-color: white;
}

.container {
  max-width: 1313px;
  margin: 0 auto;
  padding: 0 100px;
  display: flex;
  position: relative;

  @media screen and (max-width: 1300px) {
    padding: 0 32px;
  }
}

@media screen and (max-width: 1100px) {
  .container-mobile {
    flex-direction: column;
  }
}

.v-middle {
  align-items: center;
}

.h-middle {
  justify-content: center;
}

.MenuBackground-enter {
  opacity: 0;
  transition: all .25s ease-out;
  z-index: 1;
  transform: translateY(-40px);
}

.MenuBackground-enter-done {
  z-index: 5;
  transform: translateY(-25px);
}

.MenuBackground-enter.MenuBackground-enter-active {
  opacity: 1;
  z-index: 5;
  transform: translateY(-25px);
}

.MenuBackground-exit {
  opacity: 1;
  transition: all .25s ease-out;
}

.MenuBackground-exit.MenuBackground-exit-active {
  opacity: 0;
}

.MenuBurger-enter {
  opacity: 0;
  transition: all .25s ease-out;
  z-index: 900;

  .content {
    transition: all .25s ease-out;
    transform: translateY(-40px);
    opacity: 0;
  }
}

.MenuBurger-enter-done {
  opacity: 1 !important;
}

.MenuBurger-enter.MenuBurger-enter-active {
  opacity: 1;
  .content {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Customize the label (the container) */
.checkbox {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  line-height: 17px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 4px;
}

.checkbox:hover input ~ .checkmark {
  background-color: #E2E6EB;
}

.checkbox input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 5px;
  top: 2px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 3px 3px 0;
  border-radius: 1px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.touchpoint_small_avatars {
  width: 16px;
  height: 16px;
}

.touchpoint_avatar_row {
  margin-right: 5.5px;
}

.touchpoint_lines_row {
  margin-bottom: 8.5px;
  height: 1.4px;
  width: 100%;
  padding: 0 8.5px 0 8.5px;
}